import React from 'react';
import { FormattedMessage } from 'react-intl';

import IndexLayout from '../layouts';

import Footer from '../components/Footer/Footer';
import SEO from '../components/SEO/SEO';

import LegalDocuments from '../components/Legal/LegalDocuments/LegalDocuments';
import LegalHero from '../components/Legal/LegalHero/LegalHero';
import { LocaleTypes } from '../data/locales';

interface LegalPageProps {
  pathContext: {
    localeCode: LocaleTypes;
  };
  location: Location;
}

const LegalPage: React.FC<LegalPageProps> = ({ pathContext, location }) => {

  const content = () => (
    <>
      <SEO titleId="legal.title" />

      <LegalHero>
        <h1><FormattedMessage id="legal.hero.title"/></h1>
        <p className="lead"><FormattedMessage id="legal.hero.subtitle"/></p>
      </LegalHero>
      <LegalDocuments />
      <Footer theme="gray" />
    </>
  );

  return <IndexLayout render={ content } locale={pathContext.localeCode} location={location} />
}

export default LegalPage;
