import React, { useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import LocaleContext from '../../../contexts/LocaleContext';

import { graphql, useStaticQuery} from 'gatsby';
import LocalizedLink from '../../LocalizedLink/LocalizedLink';

import './styles.scss';
import { Download } from '@kushki/suka-react-system-icons';

const LegalDocuments: React.FC = () => {
  const { allContentfulLegalDocument, allContentfulCountry } = useStaticQuery(
    graphql`
      query {
        allContentfulLegalDocument(sort: {fields:country___code, order: ASC}) {
          edges {
            node {
              slug
              title
              node_locale
              country {
                code
              }
              file {
                title
                localFile {
                  publicURL
                }
              }
            }
          }
        }
        allContentfulCountry(sort: {fields: code, order: ASC}) {
          edges {
            node {
              name
              code
              node_locale
              countryBall {
                localFile {
                  publicURL
                }
              }
            }
          }
        }
      }
    `,
  );

  const localeCode = useContext(LocaleContext);


  const documents = allContentfulLegalDocument.edges.map((edge: any) => edge.node).filter((node: any) => node.node_locale === localeCode);
  const documentsWithCountry = documents.filter((document: any) => document.country);
  let documentsWithoutCountry = documents.filter((document: any) => !document.country);
  if (localeCode === 'es') {
    documentsWithoutCountry = documentsWithoutCountry.filter((document: any) => document.slug !== 'anti-money-laundering-y-counter-terrorist-financing-risk-appetite-statement');
  }

  const countries = allContentfulCountry.edges.map((edge: any) => edge.node).filter((node: any) => node.node_locale === localeCode);

  return (
    <section className="py-layout-4">
      <div className="container">
        <div className="row">
          <div className="col-12 col-lg-8 offset-lg-2">
            {
              countries.map((country: any) => {
                const filteredDocuments = documentsWithCountry
                  .filter((document: any) =>
                    document.country.code === country.code
                  );

                if(filteredDocuments.length > 0) {
                  return (
                    <div key={country.code} className="mb-5">
                      <h5
                        className="text-dark-gray-3 mb-3 d-flex align-items-center"
                      >
                        <img
                          src={country.countryBall.localFile.publicURL}
                          height="22" width="22"
                          className="mr-2"
                        />
                        {country.name}
                      </h5>

                      <ul>
                      {
                        filteredDocuments
                        .map((document: any) =>
                          <li key={document.slug}>
                            <LocalizedLink
                              to={`/legal/${document.slug}`}
                            >
                              {document.title}
                            </LocalizedLink>
                          </li>
                        )
                      }
                      </ul>
                    </div>
                  )
                }
              })
            }

            <p className="lead text-dark-gray-2">
              <FormattedMessage id="legal.more_info"
                values={{
                  link: <a href="mailto:soporte@kushkipagos.com">soporte@kushkipagos.com</a>
                }}
              />
            </p>

            <hr />

            <h5 className="text-dark-gray-3 mb-3">
              <FormattedMessage id="legal.other_documents" />
            </h5>

            <ul>
              {
                documentsWithoutCountry.map((document: any) => {
                  const file = document.file ? document.file.localFile.publicURL : undefined;
                  return (
                    <li key={document.slug} style={{marginBottom: "10px"}}>
                        
                      {
                        file &&
                        <a
                          href={file}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="lead ml-2" style={{fontSize: "1rem"}}
                        >
                          {document.file.title} <Download />
                        </a>
                      }
                    </li>
                  )
                })
              }
            </ul>
          </div>
        </div>
      </div>
    </section>
  )
};

export default LegalDocuments;
